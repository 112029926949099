<template>
    <div class="tabmenudemo-content">
        <div class="grid p-fluid" >
            <div class="col-12 p-2 -mb-3">
                <Menu :slug="slug" :active="active" />

                <div class="card">
                    <div class="field" v-if="pageLoading">
                        <Skeleton height="24rem" width="100%"/>
                    </div>
                    <div class="grid p-fluid" v-else>
                        <div class="col-12">
                            <div class="grid" v-for="(item, sIndex) in valid$.collection.$model" :key="sIndex">
                                <div class="col-12">
                                    <div class="card">
                                        <div class="flex mb-2">
                                            <div class="flex align-items-center">
                                                <InputText class="p-inputtext-sm" type="text" v-model="item.name" />
                                            </div>
                                            <div class="flex flex-grow-1">
                                                <Divider align="right">
                                                    <Button icon="pi pi-plus" class="p-button-raised p-button-text p-button-plain p-button-sm" @click="addActivities(sIndex)" label="Add Activity"/>
                                                </Divider>
                                            </div>
                                        </div>
                                        <div class="p-fluid grid" v-for="(activity, index)  in item.activities" :key="index">
                                            <div class="field col-12 lg:col-2">
                                                <div class="p-float-label p-fluid">
                                                    <Calendar
                                                        :class="{'p-invalid': valid$.collection.$each.$response.$data[sIndex].activities.$each.$data[index].time.$invalid && submitted}"
                                                        id="time"
                                                        v-model="activity.time"
                                                        :timeOnly="true"
                                                        hourFormat="12"
                                                        :showIcon="true"
                                                    />
                                                    <label for="time">Time <span style="color: #ff4444">*</span></label>
                                                </div>
                                                <span v-if="valid$.collection.$each.$response.$data[sIndex].activities.$each.$data[index].time.$invalid && submitted">
                                                    <span
                                                        id="country-error"
                                                        v-for="(error, index) of valid$.collection.$each.$response.$errors[sIndex].activities[0].$response.$errors[index].time"
                                                        :key="index"
                                                    >
                                                        <small class="p-error">{{error.$message.replace("Value", "Time")}}</small>
                                                    </span>
                                                </span>
                                            </div>
                                            <div class="field col-12 lg:col-4">
                                                <span class="p-float-label p-fluid">
                                                    <InputText
                                                        :class="{'p-invalid': valid$.collection.$each.$response.$data[sIndex].activities.$each.$data[index].title.$invalid && submitted}"
                                                        id="title"
                                                        type="text"
                                                        v-model="activity.title"
                                                    />
                                                    <label for="title">Title <span style="color: #ff4444">*</span></label>
                                                </span>
                                                <span v-if="valid$.collection.$each.$response.$data[sIndex].activities.$each.$data[index].title.$invalid && submitted">
                                                    <span
                                                        id="country-error"
                                                        v-for="(error, index) of valid$.collection.$each.$response.$errors[sIndex].activities[0].$response.$errors[index].title"
                                                        :key="index"
                                                    >
                                                        <small class="p-error">{{error.$message.replace("Value", "Title")}}</small>
                                                    </span>
                                                </span>
                                            </div>
                                            <div class="field col-12 lg:col-6">
                                                <div class="p-inputgroup">
                                                    <div class="p-float-label p-fluid">
                                                        <InputText
                                                            :class="{'p-invalid': valid$.collection.$each.$response.$data[sIndex].activities.$each.$data[index].description.$invalid && submitted}"
                                                            id="description"
                                                            type="text"
                                                            v-model="activity.description"
                                                        />
                                                        <Button
                                                            v-if="item.activities.length > 1"
                                                            @click="removeActivities(sIndex, index)"
                                                            icon="pi pi-times"
                                                            class="p-button-raised p-button-danger ml-1"
                                                        />
                                                        <label for="description">Description <span style="color: #ff4444">*</span></label>
                                                    </div>
                                                </div>
                                                <span v-if="valid$.collection.$each.$response.$data[sIndex].activities.$each.$data[index].description.$invalid && submitted">
                                                    <span
                                                        id="country-error"
                                                        v-for="(error, index) of valid$.collection.$each.$response.$errors[sIndex].activities[0].$response.$errors[index].description"
                                                        :key="index"
                                                    >
                                                        <small class="p-error">{{error.$message.replace("Value", "Description")}}</small>
                                                    </span>
                                                </span>
                                            </div>
                                        </div>
                                        <div class="flex">
                                            <div class="flex flex-none">
                                                <Button class="p-button p-button-danger" icon="pi pi-times" :label="'Remove ' + item.name" v-if="valid$.collection.$model.length > 1"  @click="removeSchedule(sIndex)"/>
                                            </div>
                                        </div>
                                    </div>
                                </div>                           
                                <div class="col-12">
                                    <div class="flex justify-content-center flex-wrap">
                                        <div class="flex flex-none">
                                            <Button class="p-button-success" icon="pi pi-plus" label="Add New Day" v-if="sIndex == valid$.collection.$model.length-1" @click="addSchedule(sIndex)"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <br>
                    <br>
                    <Button v-if="!pageLoading" label="Update Schedule" icon="pi pi-check" :loading="loading" @click="updateSchedule(!valid$.$invalid)" class="p-button-outlined mt-2" style="width: 80%; margin: auto; display: block;" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { reactive, ref} from 'vue';
import { useToast } from 'primevue/usetoast';
import { useRoute } from 'vue-router';
import Axios from 'axios';
import { useRouter } from 'vue-router';
import useVuelidate from '@vuelidate/core';
import { required, helpers } from '@vuelidate/validators';
import Menu from './TabMenu.vue';
import store from '../../../vuex';
import moment from 'moment';

export default {
    components: {
        Menu
    },
    setup(){
        const toast = useToast();
        const router = useRouter();
        const route = useRoute();

        const slug = ref();
        const tour = ref();
        const loading = ref(false);
        const pageLoading = ref(true);
        const active = ref(5);

        const submitted = ref(false);

        const state = reactive({
            collection: [
                { 
                    day: "",
                    name: "",
                    activities: [
                        { time: '', title: '', description: '' }
                    ]
                }
            ]
        });

        const rules = {
            collection: {
                $each: helpers.forEach({
                    day: { required },
                    activities: {
                        $each: helpers.forEach({
                            time: { required },
                            title: { required },
                            description: {required}
                        })
                    }
                })
            },
        };

        const valid$ = useVuelidate (rules, state);

        slug.value = route.params.slug;

        const getSingleTour = () =>{
            pageLoading.value = true;
            Axios
            .get("api/tour/view/"+slug.value)
            .then((res) => {
                if(res.data.response=="success") {
                    pageLoading.value = false;
                    tour.value=res.data.data;

                    state.collection = res.data.data.schedule;
                    // for (let item of state.collection) {
                    //     item.name = 'Day ' + item.day;
                    // }
                }
                else {
                    console.log(res.data.message);
                }
            })
            .catch((err) => {
                console.log(err);
            });
        }
        getSingleTour();

        const addActivities = (index) => {
            state.collection[index].activities.push({
                time: '',
                title: '',
                description:''
            });
        };
        const removeActivities = (sIndex, index) => {
            state.collection[sIndex].activities.splice(index, 1);
        };
        const addSchedule = (sIndex) => {
            state.collection.push({
                day: sIndex + 2,
                name: 'Day ' + parseInt(sIndex+2),
                activities: [
                    { time: '', title: '', description: '' }
                ]}
            );
        };
        const removeSchedule = (sIndex) => {
            if(state.collection.length > sIndex+1){
                for( var i = sIndex+1; i< state.collection.length; i++){
                    state.collection[i].day = i;
                }
            }
            state.collection.splice(sIndex, 1);
        };

        const showToast = (severity, summary, detail) => {
            toast.add({severity: severity, summary: summary, detail: detail, life: 5000});
        };

        const backToDetails = () => {
            router.replace({ name: 'details', params: {slug: slug.value}});
        };

        const updateSchedule = (isFormValid) =>{
            submitted.value = true;
            if (!isFormValid) {
                return;
            }
            const formData1 = new FormData();

            loading.value = true;

            const agency_id = ref();
            const userData = ref();

            userData.value = store.getters.userData;
            userData.value = JSON.parse(userData.value);

            agency_id.value = userData.value.agency_id;
            
            for (let i=0; i<state.collection.length; i++){
                formData1.append('schedule['+i+'][day]', state.collection[i].day);
                formData1.append('schedule['+i+'][name]', state.collection[i].name);
                for (let j=0; j<state.collection[i].activities.length; j++){
                    if(typeof (state.collection[i].activities[j].time) == "object"){
                        formData1.append('schedule['+i+'][activities]['+j+'][time]', moment(state.collection[i].activities[j].time).format("hh:mm a"));
                    }else{
                        formData1.append('schedule['+i+'][activities]['+j+'][time]', state.collection[i].activities[j].time);
                    }
                    formData1.append('schedule['+i+'][activities]['+j+'][title]', state.collection[i].activities[j].title);
                    formData1.append('schedule['+i+'][activities]['+j+'][description]', state.collection[i].activities[j].description);
                }
            }
            formData1.append("agency_id", agency_id.value);
            formData1.append("manager_id", tour.value.manager_id);
            formData1.append("_method", "PUT");

            Axios
            .post(`api/operator/tour/update-schedule/${tour.value.id}`, formData1, {
                config: {
                    headers: {'Content-Type': 'multipart/form-data' }
                }
            })
            .then((res) => {
                loading.value = false;
                if(res.data.response == "success") {
                    showToast("success", "Success", res.data.message);
                    slug.value = res.data.data.slug;
                    getSingleTour();
                }
                else{
                    loading.value = false;
                    showToast("error", "Error", res.data.message);
                    console.log(res.data.message);
                }
            })
            .catch((err) => {
                loading.value = false;
                console.log(err);
                showToast("error", "Error", "Something went wrong. Try again!");
            });

        };


        return{
            valid$,
            submitted,
            
            tour,
            loading,
            pageLoading,
            slug,
            active,

            addActivities,
            removeActivities,
            addSchedule,
            removeSchedule,
            updateSchedule,
            backToDetails,
            state,
        }
    }
}
</script>

<style>

</style>