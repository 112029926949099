<template>
    <div class="block-content">
        <div class="mb-1">
            <div class="align-items-center justify-content-between flex w-full surface-overlay left-0 top-100 z-1 shadow-2">
                <ul class="list-none p-0 m-0 flex lg:align-items-center overflow-x-auto select-none flex-column lg:flex-row">
                    <li class="lg:px-2">
                        <router-link :to="{ name: 'u-basic', params: {slug: slug} }" :class="{'border-blue-500 text-blue-500 hover:border-blue-500' : active == 1 }" class="cursor-pointer px-4 py-3 flex align-items-center border-bottom-2 hover:border-500 transition-colors transition-duration-150 p-ripple text-700 border-transparent">
                            <i class="pi pi-home mr-2"></i>
                            <span class="font-medium">Basic</span>
                        </router-link>
                    </li>
                    <li class="flex align-items-center">
                        <div class="lg:border-right-1 lg:surface-border min-h-full lg:py-3"></div>
                    </li>
                    <li class="lg:px-2">
                        <router-link :to="{ name: 'u-schedule', params: {slug: slug} }" :class="{'border-blue-500 text-blue-500 hover:border-blue-500' : active == 5 }" class="cursor-pointer px-4 py-3 flex align-items-center border-bottom-2 hover:border-500 transition-colors transition-duration-150 p-ripple text-700 border-transparent">
                            <i class="pi pi-map mr-2"></i>
                            <span class="font-medium">Schedule</span>
                        </router-link>
                    </li>
                    <li class="flex align-items-center">
                        <div class="lg:border-right-1 lg:surface-border min-h-full lg:py-3"></div>
                    </li>
                    <li class="lg:px-2">
                        <router-link :to="{ name: 'u-address', params: {slug: slug} }" :class="{'border-blue-500 text-blue-500 hover:border-blue-500' : active == 2 }" class="cursor-pointer px-4 py-3 flex align-items-center border-bottom-2 hover:border-500 transition-colors transition-duration-150 p-ripple text-700 border-transparent">
                            <i class="pi pi-map mr-2"></i>
                            <span class="font-medium">Address</span>
                        </router-link>
                    </li>
                    <li class="flex align-items-center">
                        <div class="lg:border-right-1 lg:surface-border min-h-full lg:py-3"></div>
                    </li>
                    <li class="lg:px-2">
                        <router-link :to="{ name: 'u-details', params: {slug: slug} }" :class="{'border-blue-500 text-blue-500 hover:border-blue-500' : active == 3 }" class="cursor-pointer px-4 py-3 flex align-items-center border-bottom-2 hover:border-500 transition-colors transition-duration-150 p-ripple text-700 border-transparent">
                            <i class="pi pi-map mr-2"></i>
                            <span class="font-medium">Details</span>
                        </router-link>
                    </li>
                    <li class="flex align-items-center">
                        <div class="lg:border-right-1 lg:surface-border min-h-full lg:py-3"></div>
                    </li>
                    <li class="lg:px-2">
                        <router-link :to="{ name: 'u-pictures', params: {slug: slug} }" :class="{'border-blue-500 text-blue-500 hover:border-blue-500' : active == 4 }" class="cursor-pointer px-4 py-3 flex align-items-center border-bottom-2 hover:border-500 transition-colors transition-duration-150 p-ripple text-700 border-transparent">
                            <i class="pi pi-map mr-2"></i>
                            <span class="font-medium">Pictures</span>
                        </router-link>
                    </li>
                </ul>
                <ul class="list-none p-0 m-0 flex lg:align-items-center select-none flex-column lg:flex-row border-top-1 surface-border lg:border-top-none mr-2">
                    <Button icon="pi pi-fw pi-eye" label="Details" @click="backToDetails" class="p-button-raised p-button-info p-2 w-auto"/>
                    <!-- <span class="m-2"></span>
                    <Button icon="pi pi-fw pi-arrow-left" label="Back to List" @click="backToList" class="p-button-raised p-button p-2 w-auto"/> -->
                </ul>
            </div>
        </div>
        <router-view />
    </div>
    <router-view />
</template>

<script>
import {  ref } from 'vue';
import { useRouter } from 'vue-router';
// import { ref } from '@vue/reactivity';

export default {
    props:['slug', 'active'],
    name: 'TabMenu',
    setup(props) {
        const router = useRouter();
        const currentSlug = ref(props.slug);
        const activeMenu = ref(props.active);
        const backToDetails = () => {
            router.replace({ name: 'details', params: {slug: currentSlug.value}});
        };
        const backToList = () => {
            router.replace({ name: 'tours', params: {slug: currentSlug.value}});
        };

        return {
            currentSlug,
            activeMenu,
            backToDetails,
            backToList
        }
    }
}
</script>

<style>

</style>